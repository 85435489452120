import './instrument'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { FontStylesheet } from '@farewill/ui/base'

import history from 'startup/common/history'
import { configureCookies, getCookieConfig } from 'lib/storage/cookies/config'
import { configureStore } from 'store'

import App from './App'
import setTrackingCookie from './setTrackingCookie'
import { configureOnboarding } from './configureOnboarding'
import { storeReferralParameters } from './storeReferralParameters'
import { storeVoucherCode } from './storeVoucherCode'
import storeQueryParameters from './storeQueryParameters'
import { init as initSplitSdk } from '../common/store/initSplitSdk'

const initialState = window.INITIAL_STATE || {}
const trackingCookieDomain = initialState.config.TRACKING_COOKIE_DOMAIN

import '../common/ui/styles/main.styl'

// We need the cookies module configured before Redux store is initialised
// as we instantiate the Split.io SDK as part of store initialisation, and the
// SplitSDK initialisation requires us to pass the client id (which we store in
// a cookie).
//
// Note: Ideally, we'd refactor so that the cookie names could be imported
// statically (as we do in .com). This would mean we wouldn't need this
// `configureCookies` function. However, currently some of the cookies code is
// referenced on the code path used for server startup, which breaks since
// `window` is undefined on the server.
configureCookies(window.INITIAL_STATE.config)

// Set up Redux (note: this API requires redux@>=3.1.0):
const store = configureStore(initialState)

// Set up Split Redux SDK
store.dispatch(initSplitSdk())

/**
 * Expose history and store objects when run via Cypress.
 * history
 *   We use this as part of a `goToRoute` custom Cypress command that
 *   allows us to navigate to an route without performing a browser
 *   level page navigation.
 * store
 *   Used by a `dispatch` custom Cypress command that allows us to update data
 *   in the store. This allows us to update/mock feature flags and other config
 *   variables in our Cypress tests.
 */
if (window.Cypress) {
  window._history = history
  window._store = store
}

const container = document.getElementById('root')

const render = () => {
  const { CookieNames } = getCookieConfig()
  setTrackingCookie({
    cookieName: CookieNames.LAST_TOUCH_TRACKING,
    cookieDomain: trackingCookieDomain,
    sessionStorage: window.sessionStorage,
    location: history.location,
    referrer: document.referrer,
  })
  configureOnboarding(store)
  storeQueryParameters(store)
  storeReferralParameters(store)
  storeVoucherCode(store)

  ReactDOM.render(
    <React.StrictMode>
      <FontStylesheet assetFolder="/fonts/" />
      {/* NOTE: We don't need to inject @farewill/ui's BaseStylesheet here as
          it's inserted as a global style inside InitialLayout, and those styles
          are not removed from the DOM when client rendering takes over */}
      <Provider store={store}>
        <App history={history} store={store} />
      </Provider>
    </React.StrictMode>,
    container
  )
}

render()

if (module.hot) {
  module.hot.accept('../common/startup/common/routes', () => {
    setTimeout(render)
  })
}
