import * as Sentry from '@sentry/react'
import history from 'startup/common/history'

const environment = process.env.RELEASE_ENV
if (['production', 'staging'].includes(environment)) {
  /**
   * Errors with descriptions which contain the following strings will be ignored
   * and not reported to Sentry.
   *
   * See https://docs.sentry.io/clients/javascript/config/
   *
   * [1] This error is throw outside our app as part of Office 365's Safe Link
   *     functionality. See https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-865857552
   */
  const SENTRY_IGNORED_ERRORS = [
    'Object Not Found Matching Id', // [1]
  ]

  const dsn = process.env.SENTRY_API_URL
  const release = process.env.RELEASE_HASH
  Sentry.init({
    dsn,
    release,
    environment,
    ignoreErrors: SENTRY_IGNORED_ERRORS,
    beforeBreadcrumb(breadcrumb) {
      // Ignore Snowplow XHRs in Sentry breadcrumbs as they are extremely noisy.
      if (
        breadcrumb.type == 'http' &&
        breadcrumb.data?.url?.startsWith(
          'https://tracker.snowplow-farewill.com'
        )
      ) {
        return null
      }
      return breadcrumb
    },
    integrations: [
      Sentry.replayIntegration({
        // See: https://docs.sentry.io/platforms/javascript/guides/react/session-replay/privacy/#privacy-configuration
        maskAllInputs: true,
        maskAllText: true,
        blockAllMedia: false,
        unmask: ['[data-sentry-unmask]', 'button', 'a', 'h1', 'h2', 'h3', 'h4'],
        mask: ['[data-sentry-mask]'],
        block: ['[data-sentry-block]'],
      }),
      Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    ],

    // Capture Replay for 1% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
  })

  window.Sentry = Sentry
}
