import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { GTR, COLOR } from '@farewill/ui/tokens'

import FooterHomeLink from './components/FooterHomeLink'
import FooterLink from './components/FooterLink'

const StyledFooter = styled.footer`
  position: relative;
  overflow: hidden;
  background-color: ${COLOR.WHITE};
  border-top: 1px solid ${COLOR.GREY.LIGHT};
`

const StyledContentWrapper = styled(Wrapper)`
  display: flex;
`

const StyledFooterLinkContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  max-width: 75%;
`

const StyledFooterLinkWrapper = styled.li`
  padding: ${GTR.S} 0 0;
  margin-right: ${GTR.S};
`

const footerLinks = (marketingSiteUrl) => [
  { url: `${marketingSiteUrl}/terms`, text: 'Terms', isExternal: true },
  {
    url: `${marketingSiteUrl}/privacy`,
    text: 'Privacy Policy',
    isExternal: true,
  },
  {
    url: `${marketingSiteUrl}/cookies`,
    text: 'Cookie Policy',
    isExternal: true,
  },
]

const Footer = ({ marketingSiteUrl }) => {
  return (
    <StyledFooter>
      <StyledContentWrapper
        container
        containerPaddingBottom="M"
        containerPaddingTop="M"
      >
        <FooterHomeLink logoVariant="dark" />
        <StyledFooterLinkContainer>
          {footerLinks(marketingSiteUrl).map((link, index) => (
            <StyledFooterLinkWrapper key={index}>
              <FooterLink isExternal={link.isExternal} url={link.url}>
                {link.text}
              </FooterLink>
            </StyledFooterLinkWrapper>
          ))}
        </StyledFooterLinkContainer>
      </StyledContentWrapper>
    </StyledFooter>
  )
}

Footer.propTypes = {
  marketingSiteUrl: PropTypes.string.isRequired,
}

export default Footer
