import { createSelector } from 'reselect'

import { getUser } from '@/common/redux/user/selectors'

const getSubscriptions = (state) => state.subscriptions

export const hasActiveSubscription = createSelector(
  [getSubscriptions, getUser],
  (subscriptions, user) =>
    subscriptions.some(
      (subscription) =>
        ['trialing', 'active'].includes(subscription.status) &&
        subscription.userId === user._id
    )
)

export const hasSubscriptions = createSelector(
  getSubscriptions,
  (subscriptions) => subscriptions.length > 0
)

export const hasUserSubscription = createSelector(
  [getSubscriptions, getUser],
  (subscriptions, user) =>
    subscriptions.some((subscription) => subscription.userId === user._id)
)

export const getUserSubscription = createSelector(
  [getSubscriptions, getUser],
  (subscriptions, user) =>
    subscriptions.find((subscription) => subscription.userId === user._id)
)

export const getPartnerSubscription = createSelector(
  [getSubscriptions, getUser],
  (subscriptions, user) =>
    subscriptions.find((subscription) => subscription.userId !== user._id)
)

export const hasSubscriptionPaidByPartner = createSelector(
  [hasUserSubscription, getUser],
  (hasUserSubscription, user) => hasUserSubscription && !user.customerId
)

export const hasChargeableSubscription = createSelector(
  getSubscriptions,
  (subscriptions) =>
    subscriptions.some((subscription) => subscription.status !== 'canceled')
)
